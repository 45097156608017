import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ServicesForm from "../components/servicesForm";

const ServicesPage = () => (
  <Layout>
    <div>
      <h1>Κάνε μας μια ερώτηση</h1>
      <ServicesForm />
    </div>
  </Layout>
);

export const Head = () => <Seo title="Services Virtual Sun" />;

export default ServicesPage;
