import React from "react";

const ServicesForm = () => {
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    alert("Form submitted");
  };
  return (
    <>
      <div className="mt-10">
        <div className="flex flex-col">
          <div className="w-full mt-5 md:mt-0">
            <form action="#" method="POST" onSubmit={onSubmit}>
              <div className="rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Όνομα
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="mt-1 p-1 block w-full sm:text-sm border border-vs-yellow rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Επώνυμο
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="mt-1 p-1 block w-full sm:text-sm border border-vs-yellow rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        name="email-address"
                        id="email-address"
                        autoComplete="email"
                        className="mt-1 p-1 block w-full sm:text-sm border border-vs-yellow rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Ερώτηση
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          className="mt-1 p-1 block w-full sm:text-sm border border-vs-yellow rounded-md"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border-transparent text-sm font-medium rounded-md text-vs-yellow bg-vs-white hover:bg-vs-yellow focus:outline-none hover:border-vs-white border hover:text-vs-white"
                  >
                    Αποστολή
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesForm;
